<template>
  <div ref="observable"></div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "IntersectionObserver",
  props: {
    root: {
      type: HTMLElement,
      default: null,
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      observer: null,
    }
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          this.$emit('intersected')
        }
      }
    }, {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    })
    this.observer.observe(this.$refs.observable)
  },
  beforeUnmount() {
    this.observer.disconnect()
  },
})
</script>
